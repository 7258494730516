<template>
    <modal ref="modalCrearDriver" titulo="Crear Driver" tamano="modal-lg" no-aceptar adicional="Crear" @adicional="crearDriver()">
        <div class="row mx-0 mb-4 justify-center">
            <div class="col-auto">
                <slim-cropper 
                ref="fotoDriver" 
                :options="slimOptions" 
                class="border cr-pointer" 
                style="height:162px;width:162px;background-color:#F5F5F5;border-radius:6px;" 
                />
            </div>
        </div>
        <ValidationObserver ref="validacion" v-slot="{ valid }">
            <div class="row mx-0 justify-center mb-4 mt-2">
                <div class="col-5">
                    <p class="text-general pl-3 f-14">Nombre</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Nombre">
                        <el-input v-model="nombre" placeholder="Nombre del Driver" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <p class="text-general pl-3 f-14 mt-4">Documento de identificación</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Identificación">
                        <el-input v-model="documento" placeholder="Documento" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-5">
                    <p class="text-general pl-3 f-14">Teléfono</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="teléfono">
                        <el-input v-model="telefono" placeholder="Teléfono" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <p class="text-general pl-3 f-14 mt-4">Id_cargamos</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Id">
                        <el-input v-model="id" placeholder="Id" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { extend } from 'vee-validate';
import Drivers from '~/services/drivers/drivers'
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            nombre: '',
            telefono: '',
            documento: '',
            id: '',

        }
    },
    methods: {
        toggle(){
            this.limpiar()
            this.$refs.modalCrearDriver.toggle();
        },
        async crearDriver(){
            let archivo = this.$refs.fotoDriver.instanciaCrop.dataBase64.output
            let form = {
                nombre: this.nombre,
                identificacion: this.documento,
                telefono: this.telefono,
                cargamos_id: this.id,
                imagen: archivo.image
            }
            const valid = await this.$refs.validacion.validate()
            if(!valid){
                this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                return false
            }
            if(form.nombre && form.identificacion && form.telefono && form.cargamos_id){
                try {
                    const  data  = await Drivers.crearDrivers(form);
                    this.notificacion("Éxito","Driver creado con éxito","success")
                    this.$emit('update')
                    this.$refs.validacion.reset()
                    this.$refs.modalCrearDriver.toggle();
                } catch (e){
                    return this.error_catch(e);
                }
            }else this.notificacion("Error","Campos obligatorios","warning")
        },
        limpiar(){
            this.nombre = ''
            this.telefono = ''
            this.documento = ''
            this.id = ''
            this.$refs.fotoDriver.instanciaCrop.remove()
            
        }
    }
}
</script>