var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalCrearDriver",attrs:{"titulo":"Crear Driver","tamano":"modal-lg","no-aceptar":"","adicional":"Crear"},on:{"adicional":function($event){return _vm.crearDriver()}}},[_c('div',{staticClass:"row mx-0 mb-4 justify-center"},[_c('div',{staticClass:"col-auto"},[_c('slim-cropper',{ref:"fotoDriver",staticClass:"border cr-pointer",staticStyle:{"height":"162px","width":"162px","background-color":"#F5F5F5","border-radius":"6px"},attrs:{"options":_vm.slimOptions}})],1)]),_c('ValidationObserver',{ref:"validacion",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"row mx-0 justify-center mb-4 mt-2"},[_c('div',{staticClass:"col-5"},[_c('p',{staticClass:"text-general pl-3 f-14"},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre del Driver"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('p',{staticClass:"text-general pl-3 f-14 mt-4"},[_vm._v("Documento de identificación")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Identificación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Documento"},model:{value:(_vm.documento),callback:function ($$v) {_vm.documento=$$v},expression:"documento"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-5"},[_c('p',{staticClass:"text-general pl-3 f-14"},[_vm._v("Teléfono")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Teléfono"},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('p',{staticClass:"text-general pl-3 f-14 mt-4"},[_vm._v("Id_cargamos")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Id"},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }